import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  dsn: "https://ed70602bc0a4ad56d1119902a89dff52@o4506754063335424.ingest.us.sentry.io/4507250735185921",
  tracesSampleRate: 0.25,
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1,
  enabled: window.location.hostname !== "localhost",
  environment: window.ENV.VERCEL_ENV,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // eslint-disable-next-line import/namespace
    // Sentry.replayIntegration(),
  ],
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
